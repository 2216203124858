import Vue from 'vue'
import Router from 'vue-router'

import { store } from '../main.js'

import Home from './home'
import Login from './login'
import User from './user'
import Profile from './profile'
import Aud from './aud'
import Paymentandsuppliers from './paymentandsuppliers'
import Products from './products'
import Suppliers from './suppliers'
import PurchaseOrder from './purchaseOrder'
import Batch from './batch'
import Invoice from './invoice'
import Test from './test'
import Design from './design'
import Presentation from './presentation'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkActiveClass: "active",
    hashbang: false,
    routes: [
        Login,
        Home,
        User,
        Profile,
        Paymentandsuppliers,
        Products,
        Suppliers,
        PurchaseOrder,
        Batch,
        Test,
        Invoice,
        Aud,
        Design,
        Presentation
    ]
});

router.beforeEach((to, from, next) => {
    const authenticated = localStorage.getItem('token');

    if (!authenticated && to.path.toString() != '/login') {
        next('/login');

        return true;
    }

    if (authenticated && to.path.toString() == '/login') {
        next('/');

        return true;
    }

    next();
});

router.afterEach((to, from) => {
    let _name = to.name;
    let _route = to.fullPath;

    if (_name === 'Home') {
        _name = '';
        _route = '';
    }

    store.commit("updateBreadCrumbs", { name: _name, route: _route })
});

export default router
