import Invoice from '../components/Invoice/Invoice'

let routes = {
    path: '/invoices',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Notas fiscais',
            component: Invoice
        },
    ]
}

export default routes
