<template>
	<div>
		<div class='text-right'>
			<button id="refresh-btn" class='btn btn-normal purple' @click="getData">Recarregar</button>
		</div>
		<div class='row mt-5'>
			<div class='col-8 offset-2'>
				<div :class="[
								'batch-item row', 
								{'opened-logs': show_batch_logs_id == batch.id}
							]" v-for="batch in batches" :key="batch.id"
							@click="getLogs(batch.id)"
							>
					<div class='col-6 float-left'>
						{{ formatDate(batch.created_at) }} - {{ batch.description }} - {{ batch.status.description }}
						<button v-if="!batch.origin_file_path && batch.status_id == 3" class='btn btn-normal purple float-right offset-1' @click.stop="goToTest(batch.testId)">Acessar teste</button>
					</div>
					<div class='col-6'>
						<k-progress :percent="batch.percentage_round" :line-height="30" :color="batch.status_id == 2 ? '#ff4b4b' : batch.status_id == 3 ? '#46c746' : ['#ae7ed4', '#6908b9']"></k-progress>
					</div>
					<div class='col-12' v-if="show_batch_logs_id == batch.id">
						<button class='btn btn-normal purple float-right' @click="getLogs(batch.id, true)">Recarregar logs</button>
						<br>
						<div class='clearfix'></div>
						<div class="tail-logs">
							{{ tail_logs }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import KProgress from 'k-progress';
export default {
	data() {
		return {
			batches: [],
			// batches_to_track: [],
			// batches_to_track_worker_reference: null,
			show_batch_logs_id: null,
			tail_logs: '',
			interval_worker: null
		};
	},
	methods: {
		formatDate(date) {
			const just_date_time = date.split('T')[0]
			const date_splited = just_date_time.split('-')
			return date_splited[2] + '/' + date_splited[1] + '/' + date_splited[0]
		},
		getData() {
			const api = this.$store.state.api + 'batches?paginated=false&orderByDesc[]=id&with[]=status';
			const self = this;

			self.$http.get(api)
				.then((response) => {
					self.batches = response.data.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
		},
		getLogs(id, force_same_batch = false) {
			const self = this;

			self.tail_logs = ''
			if (!force_same_batch && self.show_batch_logs_id == id) {
				self.show_batch_logs_id = null
				return
			}

			const api = self.$store.state.api + 'batches/tail-logs/' + id;

			self.$http.get(api)
				.then((response) => {
					self.tail_logs = response.data
					self.show_batch_logs_id = id
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
		},
		goToTest(id){
			this.$router.push('/design/' + id)		
		}
		// trackNotDone(){
		// 	const self = this
		// 	let batches_to_track = []
		// 	self.batches.forEach(batch => {
		// 		if(batch.percentage < 100){
		// 			batches_to_track.push(batch.id)
		// 		}
		// 	});
		// 	if(batches_to_track.length){
		// 		self.batches_to_track = batches_to_track
		// 		self.batches_to_track_worker_reference = setInterval(() => {
		// 			self.getNotDonePercentages()
		// 		}, 1000);
		// 	}
		// },
		// getNotDonePercentages(){
		// 	const self = this
		// 	if(!self.batches_to_track.length){
		// 		self.batches_to_track_worker_reference = null
		// 		return
		// 	}

		// 	const api = this.$store.state.api + 'get-batch-percentages';
		// 	const self = this;

		// 	const fd = new formatData()
		// 	self.batches_to_track.forEach(batch_to_track => {
		// 		fd.append('ids[]', batch_to_track)
		// 	});

		// 	self.$http.post(api, fd)
		// 		.then((response) => {
		// 			response.data.forEach(percentage, batch_id => {
		// 				let foundBatch = self.batches.find(x => x.id == batch_id)
		// 			});
		// 		})
		// 		.catch((error) => {
		// 			self.$message('Erro', error.response.data, 'error');
		// 		});
	},
	mounted() {
		this.getData()

		this.interval_worker = setInterval(() => {
			this.getData()
		}, 3000);
	},
	components: {
		KProgress
	},
	beforeRouteLeave (to, from , next) {
		clearInterval(this.interval_worker)
		next()
	}

};
</script>

<style>
.tail-logs {
	white-space: pre-line;
	max-height: 300px;
	overflow-y: scroll;
	margin-bottom: 10px;
	border: 1px solid #6908b9;
	border-top: none;
}

.batch-item {
	padding: 10px;
	border-radius: 20px;
	margin-bottom: 5px;
}

.opened-logs {
	background-color: #ececec;
}

.batch-item:hover {
	background-color: #ececec;
}
</style>
