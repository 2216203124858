<template>
	<div>
		<div class='row'>
			<div class='col-12 text-right'>
				<button class='btn btn-normal purple' @click="importClicked()">Importar</button>
				<input type="file" id="file" @change="save()" hidden>
				<a class='btn btn-normal purple ml-2' :href="$store.getters.url + '/layouts/suppliers'" target="_blank"><i class='fa fa-download'></i> Baixar Modelo</a>			
			</div>
		</div>
		<base-crud
			crudName="Fornecedores"
			newText="Novo Fornecedor"
			:table="table"
			:columns="columns"
			:options="options"
			:endPoint='endPoint'
			:enableAdd="false"
			:enableEdit="false"
			:enableDelete="false"
			:enableView="false"
			:dataForm="dataForm"
		>
		</base-crud>
	</div>
</template>

<script>
import BaseCrud from '../Base/BaseCrud'
export default {
	data() {
		return {
			table: 'suppliersTable',
			url: '',
			columns: ['id', 'fantasy_name'],
			tableData: ['id', 'fantasy_name'],
			options: {
				sortable: ['id', 'fantasy_name'],
				pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
				perPage: 10,
				perPageValues: [10,25,50,100],
				headings: {
					'id': 'ID',
					'fantasy_name' : 'Nome fantasia'
				}
			},
			endPoint: 'suppliers/',
			dataForm: {}
		};
	},
	methods: {
		importClicked(){
			$('#file').click()
		},
		save(){
			const file = document.getElementById('file').files[0]
			if(!file){
				return
			}
			const api = this.$store.state.api + 'import/suppliers';
			const self = this;

			const fd = self.makeFormData()
			if(!fd) return

			self.$http.post(api, fd)
				.then((response) => {
					self.$router.push('/batch')
					self.$message('Sucesso', `Importação em andamento`, 'success');
				})
				.catch((error) => {
					document.getElementById('file').value = ''
					self.$message('Erro', error.response.data, 'error');
				});
		},
		makeFormData(){
			const fd  = new FormData();

			const file = document.getElementById('file').files[0]
			if(!file){
				return
			}
			fd.append('file', file);

			return fd
		}
	},
	components: {
		BaseCrud
	},
};
</script>

<style>
</style>
