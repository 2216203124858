import Suppliers from '../components/Suppliers/Suppliers'

let routes = {
    path: '/suppliers',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Fornecedores',
            component: Suppliers
        },
    ]
}

export default routes
