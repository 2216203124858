import PurchaseOrder from '../components/PurchaseOrder/PurchaseOrder'

let routes = {
    path: '/purchase-order',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Pedido de compra',
            component: PurchaseOrder
        },
    ]
}

export default routes
