<template>
  <div>
    <div class="panel animated fadeInRightBig">
      <div class="panel-heading">
        <div class="panel-title">
          <span class="title">
            <b>Novo Teste</b>
            <i
              class="fa fa-user-circle"
              style="float: right; margin-top: 6px; font-size: 21px"
            ></i>
            <hr />
          </span>
        </div>
      </div>
      <div class="panel-body">
        <form id="form">
          <div class="row" style="font-weight: bold">
            <div class="col-md-6">
              <label for="name">Descrição</label>
              <input
                type="text"
                class="form-control"
                v-model="test.description"
              />
            </div>
            <div class="col-md-3">
              <label>Data de Início</label>
              <input
                type="text"
                v-mask="'##/##/####'"
                class="form-control"
                v-model="test.date_start"
              />
            </div>
            <div class="col-md-3">
              <label>Data de Fim</label>
              <input
                type="text"
                v-mask="'##/##/####'"
                class="form-control"
                v-model="test.date_end"
              />
            </div>
          </div>
          <br />

          <div class="container">
            <div class="row">
              <div class="mx-auto" style="font-size: 18px; font-weight: bold">
                Selecione os testes que deseja realizar e preencha seus
                parâmetros:
              </div>
              <br />
              <br />
            </div>

            <div class="row">
              <div class="mx-auto" style="font-weight: 600">
                  <label class="switch">
                    <input v-model="isSelectedAll" @click="selectAll()" value="true" type="checkbox" />
                    <span class="slider"></span>
                  </label>           
                  Selecionar Todos
              </div>
            </div>

            <div class="row" style="font-weight: 600">
              <div class="col-6">
                <div class="col-md-12 aud-box">
                  <div>
                    <label class="switch">
                      <input v-model="selectedAuds" value="1" type="checkbox" />
                      <span class="slider"></span>
                    </label>
                    AUD1 - Analisar notas fiscais repetidas por fornecedor
                  </div>
                </div>

                <div class="col-md-12 aud-box">
                  <label class="switch">
                    <input v-model="selectedAuds" value="2" type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  AUD2 - Analisar notas fiscais com valor total x% acima da
                  média anterior
                  <div class="interior-div" v-if="selectedAuds.includes('2')">
                    Porcentagem acima da média anterior:
                    <input
                      class="form-fixed"
                      v-model="audsParameters['aud2Percentage']"
                      type="number"
                      style="width: 60px"
                    />
                    %
                    <div style="font-size: 12px">(Valor recomendado: 75%)</div>
                  </div>
                </div>

                <div class="col-md-12 aud-box">
                  <label class="switch">
                    <input v-model="selectedAuds" value="4" type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  AUD4 - Analisar pagamentos ocasionais e relevantes
                  <div class="interior-div" v-if="selectedAuds.includes('4')">
                    <div class="row">
                      <div class="col-12">
                        Faixa dos pagamentos: R$
                        <input
                          class="form-fixed"
                          v-model="audsParameters['aud4Values'][0]"
                          type="number"
                          style="width: 85px"
                        />
                        até R$
                        <input
                          class="form-fixed"
                          v-model="audsParameters['aud4Values'][1]"
                          type="number"
                          style="width: 85px"
                        />
                        até R$
                        <input
                          class="form-fixed"
                          v-model="audsParameters['aud4Values'][2]"
                          type="number"
                          style="width: 85px"
                        />
                         </div>
                        </div>
                      </div>
                    </div>
                <div class="col-md-12 aud-box">
                  <label class="switch">
                    <input v-model="selectedAuds" value="5" type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  AUD5 - Analisar notas fiscais sequenciais que são de número
                  menor ou igual a x
                  <div class="interior-div" v-if="selectedAuds.includes('5')">
                    Nº máximo das notas fiscais:
                    <input
                      class="form-fixed"
                      v-model="audsParameters['aud5MaxNumber']"
                      type="number"
                      style="width: 75px"
                    />
                    <div style="font-size: 12px"></div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="col-md-12 aud-box">
                  <label class="switch">
                    <input v-model="selectedAuds" value="6" type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  AUD6 - Analisar notas fiscais com valores próximos às alçadas
                  <div class="interior-div" v-if="selectedAuds.includes('6')">
                    <div class="row">
                      <div class="col-md-auto my-auto">
                        Quantidade de alçadas:
                      </div>
                      <form @submit.prevent="showScopes()">
                        <div>
                          <input
                            class="form-fixed"
                            v-model="numberOfScopes"
                            type="number"
                            min="1"
                            max="5"
                            style="width: 50px"
                          />
                          <div class="divider" />
                          <button style="height: 30px" class="btn purple">
                            Gerar
                          </button>
                        </div>
                      </form>
                    </div>
                    <div v-for="n in parseInt(audsParameters['aud6ScopesOutput'])" :key="n">
                      <div style="padding-top: 5px">
                        Valor da alçada nº{{ n }}: R$
                        <input
                          class="form-fixed"
                          v-model="audsParameters['aud6Scopes'][n - 1]"
                          type="number"
                          style="width: 100px"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 aud-box">
                  <label class="switch">
                    <input v-model="selectedAuds" value="8" type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  AUD8 - Analisar notas fiscais que possuem valores arredondados
                  <div class="interior-div" v-if="selectedAuds.includes('8')">
                    Valor mínimo: R$
                    <input
                      class="form-fixed"
                      v-model="audsParameters['aud8MinimumValue']"
                      type="number"
                      style="width: 100px"
                    />
                  </div>
                </div>

                <div class="col-md-12 aud-box">
                  <label class="switch">
                    <input v-model="selectedAuds" value="11" type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  AUD11 - Analisar pagamentos a fornecedores que excederam o
                  próprio teto de porte da empresa
                </div>

                <div class="col-md-12 aud-box">
                  <label class="switch">
                    <input v-model="selectedAuds" value="12" type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  AUD12 - Analisar pagamentos a fornecedores recentemente
                  constituídos
                </div>
              </div>
            </div>
            <div class="row block text-right mt-5">
              <div class="mx-auto">
                <router-link to="/tests" class="btn btn-normal red mr-2">
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
                <a class="btn btn-normal purple clickable mr-2" @click="fillData">
                  Preenchimento Automático
                </a>
                <a class="btn btn-normal purple clickable" @click="save">
                  Salvar <i class="fa fa-save"></i>
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSelectedAll: false,
      selectedAuds: [],
      numberOfScopes: 0,
      audsParameters: {
        aud2Percentage: '',
        aud4Values: [],
        aud5MaxNumber: '',
        aud6ScopesOutput: 0,
        aud6Scopes: [],
        aud8MinimumValue: '',
      },
      showScopesInputs: false,
      test: {
        description: "teste",
        date_start: "01/01/1990",
        date_end: "01/01/2030",
      },
    };
  },
  methods: {
    fillData(){
      this.isSelectedAll = true
      this.numberOfScopes = 2
      this.selectedAuds = ["1","2","4","5","6","8","11","12"]
      this.audsParameters['aud2Percentage'] = 75
      this.audsParameters['aud4Values'] = [25000, 50000, 100000]
      this.audsParameters['aud5MaxNumber'] = 50
      this.audsParameters['aud6ScopesOutput'] = 2
      this.audsParameters['aud6Scopes'] = [20000, 40000]
      this.audsParameters['aud8MinimumValue'] = 20000
    },
    selectAll(){
      self = this;
      if(!self.isSelectedAll){
        self.selectedAuds = ["1","2","4","5","6","8","11","12"]
      } else {
        self.selectedAuds = []
      }      
    },
    showScopes() {
      this.audsParameters["aud6Scopes"] = [];
      this.audsParameters['aud6ScopesOutput'] = this.numberOfScopes;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "tests";

      if (self.test.id) {
        api += "/" + self.test.id;
      }

      const fd = self.makeFormData();

      self.$http
        .post(api, fd)
        .then((response) => {
          self.$message("Sucesso", `Teste cadastrado com sucesso`, "success");
          this.$router.push("/batch");
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
    makeFormData() {
      let fd = new FormData();
      fd.append("description", this.test.description);
      fd.append("date_start", this.test.date_start);
      fd.append("date_end", this.test.date_end);

      this.selectedAuds.forEach(function (aud) {
        fd.append("selectedAuds[]", aud);
      });

      fd.append("audsParameters", JSON.stringify(this.audsParameters));

      if (this.test.id) {
        fd.append("_method", "PUT");
        fd.append("id", this.test.id);
      }

      return fd;
    },
    getData(id) {
      const self = this;
      let api = self.$store.state.api + "tests/" + id;

      self.$http
        .get(api)
        .then((response) => {
          self.test = response.data.data[0];
        })
        .catch((error) => {
          self.$message("Erro", error.response.data, "error");
        });
    },
  },
  mounted: function () {
    let id = this.$route.params.id;
    if (id) {
      this.getData(id);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: purple;
}

input:focus + .slider {
  box-shadow: 0 0 1px purple;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 12px;
}

.slider.round:before {
  border-radius: 50%;
}

.purple {
  background-color: purple;
  color: white;
  align-items: center;
  display: inline-flex;
}

.divider {
  width: 10px;
  height: auto;
  display: inline-block;
}

.form-fixed {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  color: #666 !important;
  font-size: 12px;
  padding: 0 0.5rem;
  height: 30px;
}

.form-fixed::placeholder {
  color: #999;
}

.form-fixed:focus {
  border: 1px solid #6908b9;
  box-shadow: none;
  outline: none;
}

.interior-div {
  font-weight: normal;
}

.aud-box {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 5px;
  margin-bottom: 5px;
}
</style>
