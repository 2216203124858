<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title">AUD-12</div>
          <div class="card-subtitle mt-2">
            Pagamentos a fornecedores recentemente constituídos
          </div>
        </div>
        <div class="card-body chart-body">
          <bar-chart ref="my_chart" v-if="canRenderGraph" :chartdata="chart.chartdata" :options="chart.options"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '../../Charts/BarChart'
export default {
  data() {
    return {
      canRenderGraph: false,
      chart: {
        chartdata: {
          labels: [],
          datasets: [
            {
              label: "Valor total das notas fiscais",
              backgroundColor: '#4073d7',
              pointBackgroundColor: 'white',
              borderWidth: 1,
              pointBorderColor: '#4073d7',
              data: [],
            },
          ],
        },  
        options: {
          legend: {
            onClick: null
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              label(vars){
                let float = parseFloat(vars.value).toFixed(2)
                let parts = float.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                let formatted = parts.join(".").replace('.', ',')

                return 'Valor total das notas fiscais: ' + formatted
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  methods: {
    getGraph(fd){
      const self = this;
			const api = this.$store.state.api + 'aud12s/graph/'

			self.$http.post(api, fd)
				.then((response) => {
					self.chart.chartdata.labels = response.data.supplier_names
          self.chart.chartdata.datasets[0].data = response.data.totals
          self.canRenderGraph = true
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
  },
  components: {
    BarChart,
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
