<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">AUD-01</div>
            <div class="card-subtitle mt-2">
              Notas fiscais repetidas por fornecedor
            </div>
          </div>
          <div class="card-body chart-body">
            <bar-chart ref="my_chart" v-if="canRenderGraph" :chartdata="chart.chartdata" :options="chart.options"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '../../Charts/BarChart'
export default {
  data() {
    return {
      canRenderGraph: false,
      chart: {
        chartdata: {
          invoice_numbers: [],
          labels: [],
          datasets: [
            {
              label: "Valor da nota",
              data: [],
            }
          ],
        },  
        options: {
          legend: {
            display: false,
            onClick: null
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              label(tooltipItem, data){

                let float = parseFloat(tooltipItem.value).toFixed(2)
                let parts = float.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                let formatted = parts.join(".").replace('.', ',')

                var number = 0;
                data.invoice_numbers.forEach(function (array){
                  if(tooltipItem.value == array[0]){
                    number = array[1].toString();
                  }
                })
                if(tooltipItem.datasetIndex == 0){
                  return ['Valor total da nota fiscal: ' + formatted, 'Número da nota fiscal: ' + number]
                }
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  methods: {
    getGraph(fd){
      const self = this;
			const api = this.$store.state.api + 'aud1s/graph/'

      self.canRenderGraph = false

			self.$http.post(api, fd)
				.then((response) => {
					self.chart.chartdata.labels = response.data.labels
          self.chart.chartdata.datasets = response.data.datasets;
          self.chart.chartdata.invoice_numbers = response.data.invoiceNumbers;
          self.canRenderGraph = true
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
  },
  components: {
    BarChart
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
