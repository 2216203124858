import Crud from '../components/User/Crud'

let routes = {
    path: '/users',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/users/:operation?/:id?',
            name: 'Usuários',
            component: Crud
        },
    ]
};


export default routes
