import PaymentAndSuppliers from '../components/PaymentAndSuppliers/PaymentAndSuppliers'

let routes = {
    path: '/paymentandsuppliers',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Pagamentos e Fornecedores',
            component: PaymentAndSuppliers
        },
    ]
}

export default routes
