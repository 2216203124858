<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">AUD-05</div>
            <div class="card-subtitle mt-2">
              Notas fiscais têm número sequencial e menor ou igual a {{ selectedMaxNumber }}
            </div>
          </div>
          <div class="card-body chart-body">
            <bar-chart ref="my_chart" v-if="canRenderGraph" :chartdata="chart.chartdata" :options="chart.options"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '../../Charts/BarChart'
export default {
  data() {
    return {
      selectedMaxNumber: '',
      canRenderGraph: false,
      canRenderSecondGraph: false,
      chart: {
        chartdata: {
          labels: [],
          datasets: [],
        },  
        options: {
          datasets:{
            bar: {
              skipNull: true,
            }
          },
          skipNull: true,
          legend: {
            display: false,
            onClick: null
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              label(vars){
                let float = parseFloat(vars.value).toFixed(2)
                let parts = float.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                let formatted = parts.join(".").replace('.', ',')
                  return 'Valor total da nota fiscal: ' + formatted
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  methods: {
    getGraph(fd){
      const self = this;
			const api = this.$store.state.api + 'aud5s/graph/'

      self.canRenderGraph = false

			self.$http.post(api, fd)
				.then((response) => {
          self.selectedMaxNumber = response.data.selected_max_number
          self.chart.chartdata.labels = response.data.labels
          self.chart.chartdata.datasets = response.data.datasets;
          self.canRenderGraph = true
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
  },
  components: {
    BarChart,
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
