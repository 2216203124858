<template>
    <div>
        <base-crud
                crudName="Testes"
                newText="Novo Teste"
                :table="testsTable"
                :columns="columns"
                :options="options"
                :endPoint='endPoint'
                :model_endpoint='model_endpoint'
                :enableAdd="true"
                :enableEdit="false"
                :enableDelete="false"
                :enableView="false"
                :dataForm="dataForm"
                :extra="extra"
                @view="view"
        >
        </base-crud>
    </div>
</template>

<script>
    import BaseCrud from '../Base/BaseCrud'

    export default {
        data: function () {
            return {
                testsTable: 'testsTable',
                pages: [],
                url: '',
                columns: ['id', 'description', 'actions'],
                tableData: ['id', 'description'],
                options: {
                    sortable: ['id', 'description'],
                    pagination: {chunk: 10, dropdown: false, nav: 'scroll' },
                    perPage: 10,
                    perPageValues: [10,25,50,100],
                    headings: {
                        'id': 'ID',
                        'description' : 'Descrição',
                        'actions' : 'Ações'
                    },
                    requestFunction: function (data) {
                      let requestData = {};
                      let query = this.$parent.$parent.query(data.query);
                      requestData.params = data;
                      requestData.params.query = "";
                      requestData.params.with = [];
                      return this.$http.get(this.url + "?" + query, requestData).catch(
                        function (e) {
                          this.dispatch("error", e);
                        }.bind(this)
                      );
                    },
                },
                endPoint: 'tests/',
                model_endpoint: 'tests/model/',
                dataForm: {},
                extra: [
                    {
                        id: 1,
                        label: "Visualizar",
                        class: "btn btn-normal ml-1 purple",
                        title: "Visualizar",
                        name: "view",
                        icon: "fas fa-eye",
                    },
                ],
            }
        },
        methods: {
            view(props, row, index){
              this.$router.push('design/' + props.props.row.id)
            },
            query: function (query) {
              let columns = {
                id: 'id',
                description: 'description',
              };
              let filters = "&orderByDesc=id&";
              $.each(query, function (index, value) {
                filters += columns[index] + "=" + value + "&";
              });
        
              return filters;
            },
        },
        components: {
            BaseCrud
        }
    }
</script>

<style scoped>
    .VuePagination {
        text-align: center;
    }
</style>
