<template>
  <div>
    <!-- Title -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="title">Fornecedores</div>
        </div>
      </div>
    </div>
    <!-- Main Content -->
    <div class="main-content">
      <!-- Filtros -->
      <form action="">
        <div class="form-row mb-3">
          <div class="col">
            <select class="form-control">
              <option>Fornecedor</option>
            </select>
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Nome / CNPJ" />
          </div>
          <div class="col">
            <input type="date" class="form-control" />
          </div>
          <div class="col">
            <input type="date" class="form-control" />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Tipo de Fornecedor"
            />
          </div>
        </div>
      </form>
      <!-- Indicadores -->
      <div class="row">
        <div class="col-md">
          <div class="card">
            <div class="card-body position-relative overflow-hidden">
              <div class="card-small-info">
                Quantidade de Fornecedores Ativos
              </div>
              <div class="card-value-info">21</div>
              <div class="card-percent down">13,5%</div>
              <i class="fas fa-users card-icon"></i>
            </div>
          </div>
        </div>
        <div class="col-md">
          <div class="card">
            <div class="card-body position-relative overflow-hidden">
              <div class="card-small-info">Transações Realizadas</div>
              <div class="card-value-info">247</div>
              <div class="card-percent up">8,2%</div>
              <i class="fas fa-exchange-alt card-icon"></i>
            </div>
          </div>
        </div>
        <div class="col-md">
          <div class="card">
            <div class="card-body position-relative overflow-hidden">
              <div class="card-small-info">Volume Transacionado</div>
              <div class="card-value-info">35.000,00</div>
              <div class="card-percent down">11,7%</div>
              <i class="fas fa-money-bill card-icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Gráficos -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Volume(R$) X Quantidade de Red Flags</div>
            </div>
            <div class="card-body chart-body">
              <line-chart :chartdata="volumeRedFlagsChart.chartdata" :options="volumeRedFlagsChart.options"/>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Volume(R$) X Quantidade de Fraudes</div>
            </div>
            <div class="card-body chart-body">
              <line-chart :chartdata="volumeFraudsChart.chartdata" :options="volumeFraudsChart.options"/>
            </div>
          </div>
        </div>
      </div>
      <!-- Tabelas -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                Alertas Filtrados<span class="pastel-green">10</span>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Ações</th>
                      <th scope="col">ID</th>
                      <th scope="col">Fornecedor</th>
                      <th scope="col">CNPJ</th>
                      <th scope="col">Transações</th>
                      <th scope="col">R$ Transações</th>
                      <th scope="col">Red Flags</th>
                      <th scope="col">R$ Red Flags</th>
                      <th scope="col">Fraudes</th>
                      <th scope="col">R$ Fraudes</th>
                      <th scope="col">1ª Transação</th>
                      <th scope="col">Última Trans.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a
                          href="details.html"
                          class="btn btn-crud green"
                          data-tt="tooltip"
                          title="Detalhes"
                          ><i class="fas fa-info"></i
                        ></a>
                      </td>
                      <td>1050</td>
                      <td>Innovare TI</td>
                      <td>87.535.626/0001-80</td>
                      <td>12</td>
                      <td class="text-right">50.000,00</td>
                      <td>3</td>
                      <td class="text-right">36.000,00</td>
                      <td>2</td>
                      <td class="text-right">7.000,00</td>
                      <td>25/07/2016</td>
                      <td>04/04/2021</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href="details.html"
                          class="btn btn-crud green"
                          data-tt="tooltip"
                          title="Detalhes"
                          ><i class="fas fa-info"></i
                        ></a>
                      </td>
                      <td>1050</td>
                      <td>Innovare TI</td>
                      <td>87.535.626/0001-80</td>
                      <td>12</td>
                      <td class="text-right">50.000,00</td>
                      <td>3</td>
                      <td class="text-right">36.000,00</td>
                      <td>2</td>
                      <td class="text-right">7.000,00</td>
                      <td>25/07/2016</td>
                      <td>04/04/2021</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href="details.html"
                          class="btn btn-crud green"
                          data-tt="tooltip"
                          title="Detalhes"
                          ><i class="fas fa-info"></i
                        ></a>
                      </td>
                      <td>1050</td>
                      <td>Innovare TI</td>
                      <td>87.535.626/0001-80</td>
                      <td>12</td>
                      <td class="text-right">50.000,00</td>
                      <td>3</td>
                      <td class="text-right">36.000,00</td>
                      <td>2</td>
                      <td class="text-right">7.000,00</td>
                      <td>25/07/2016</td>
                      <td>04/04/2021</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href="details.html"
                          class="btn btn-crud green"
                          data-tt="tooltip"
                          title="Detalhes"
                          ><i class="fas fa-info"></i
                        ></a>
                      </td>
                      <td>1050</td>
                      <td>Innovare TI</td>
                      <td>87.535.626/0001-80</td>
                      <td>12</td>
                      <td class="text-right">50.000,00</td>
                      <td>3</td>
                      <td class="text-right">36.000,00</td>
                      <td>2</td>
                      <td class="text-right">7.000,00</td>
                      <td>25/07/2016</td>
                      <td>04/04/2021</td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href="details.html"
                          class="btn btn-crud green"
                          data-tt="tooltip"
                          title="Detalhes"
                          ><i class="fas fa-info"></i
                        ></a>
                      </td>
                      <td>1050</td>
                      <td>Innovare TI</td>
                      <td>87.535.626/0001-80</td>
                      <td>12</td>
                      <td class="text-right">50.000,00</td>
                      <td>3</td>
                      <td class="text-right">36.000,00</td>
                      <td>2</td>
                      <td class="text-right">7.000,00</td>
                      <td>25/07/2016</td>
                      <td>04/04/2021</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <a href="" class="show-more">Exibir mais</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../Charts/LineChart";
export default {
  data() {
    return {
      volumeRedFlagsChart: {
        chartdata: {
          labels: ["Jan", "Fev", "Mar", "Abr", "Mai"],
          datasets: [
            {
              label: "Volume",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#1BF0DF",
              data: [65000, 30000, 20000, 15000, 45000],
            },
            {
              label: "Quantidade",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#87D52F",
              data: [55000, 40000, 40000, 15000, 35000],
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      },
      volumeFraudsChart: {
        chartdata: {
          labels: ["Jan", "Fev", "Mar", "Abr", "Mai"],
          datasets: [
            {
              label: "Volume",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#000080",
              data: [25000, 60000, 30000, 15000, 45000],
            },
            {
              label: "Quantidade",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#DC143C",
              data: [35000, 40000, 20000, 25000, 15000],
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      },
    };
  },
  methods: {},
  components: {
    LineChart,
  },
};
</script>

<style>
</style>
