<template>
  <div class='thisss'>
    <!-- Modal -->
    <div
      class="modal fade"
      id="forgotPasswordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalSenhaTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalSenhaTitle">Esqueci minha senha</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="log-text"
            >Insira seu email cadastrado e em instantes você receberá uma nova senha.</div>
            <form action>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  id="inputEmailPass"
                  placeholder="Email"
                  v-model="email"
                >
              </div>
              <a
                @click="userRecovery()"
                type="button"
                class="btn btn-main"
              >Enviar</a>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="content" class="login login-screen h-100" style="margin-top: -90px">
      <!-- Main Content -->
      <div class="main-content h-100">
        <div class="row h-100 justify-content-center">
          <!-- Client -->
          <div class="col-4">
            <div id="login-card" class="card mb-0">
              <div class="card-header h-50">
                <!-- <img src="../assets/images/logo-innovare-dark.png" class="icon-login" alt="Icon" /> -->
                <h1 id='auditee-name-login'>Auditee</h1>
              </div>
              <div class="card-body login-title">
                <div class="row">
                  <div class="col-12">
                    <div class="title">
                      <div style="text-align: center">
                        <div class='login-welcome'>
                          Bem-vindo(a) ao Auditee!
                        </div>
                        <div class='login-description'>
                          O Auditee é uma plataforma de testes online, direcionado para identificar fraudes em transações empresariais, que atua em diversos departamentos e segmentos de mercado
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <div :class="'alert text-center alert-' + message.type" role="alert" v-if="message">
                    {{ message.text }}
                  </div>
                  <div class="form-group">
                    <label for="inputEmail1">E-mail</label>
                    <input
                      class="form-control"
                      type="email"
                      id="user"
                      v-model="email"
                      placeholder
                      required
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group mb-2">
                    <label for="inputPassword1">Senha</label>
                    <input
                      class="form-control"
                      type="password"
                      id="password"
                      v-model="password"
                      placeholder
                      required
                      @keyup.enter="login"
                    />
                  </div>
                  <a @click="showForgotPasswordModal()" class="forgot float-right clickable">Esqueci minha senha</a>
                </form>
              </div>
              <div class="card-footer" style="margin-top: -22px">
                <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-main" @click="login">Acessar Auditee</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      message: ''
    };
  },
  methods: {
    userRecovery(){
      const self = this;
      const api = this.$store.state.api + "user/recovery";

      axios
        .post(api, {
          email: self.email,
        })
        .then((response) => {
          self.message = {
            type: 'success',
            text: response.data
          };
          $("#forgotPasswordModal").modal('hide')
        })
        .catch((error) => {
          self.$message("Erro", error.response.data.error, "error");
        });
    },
    showForgotPasswordModal(){
      $("#forgotPasswordModal").modal('show')
    },
    login() {
      const self = this;
      const api = this.$store.state.api + "login";

      axios
        .post(api, {
          email: self.email,
          password: self.password,
        })
        .then((response) => {
          self.$store.commit("auth", response.data.accessToken);
          self.$router.push({ path: "/presentation" });
        })
        .catch((error) => {
          self.$message("Erro", error.response.data.error, "error");
        });
    },
  },
};
</script>

<style>
</style>
