<template>
 <div class="row">
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group text-center">
                                <div class="circular--landscape" style="margin:0 auto 0 auto">
                                    <img :src="user.picture" alt="Sem Foto">
                                </div>
                            </div>
                            <div class="profile_user">
                                <h3 class="user_name_max">{{ user.name }}</h3>
                                <p>{{ user.email }}</p>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <input type="file" name="photo" class="" id="file">
                            <hr>
                            <button class="btn btn-primary" @click="save">Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                user: {
                    id: 0,
                    name: '',
                    email: '',
                    picture: ''
                },
            }
        },
        mounted: function () {
            this.user = this.$store.state.user;
            console.log(this.user);
        },
        methods: {
            save() {
                const self = this;
                const api  = self.$store.getters.api + 'users/image/update/' + self.user.id;
                let data   = new FormData();

                data.append('id', self.user.id);
                data.append('photo', document.getElementById('file').files[0]);

                self.$http.post(api, data)
                    .then(function (response) {
                        self.user.photo = self.user.photo + '?' + self.makeid();

                        self.$store.commit('profileUpdate');
                    })
                    .catch(function (err) {
                        // Do nothing
                    });

                    // this.$router.push('/user');
            },
            makeid() {
                let text = '';
                let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

                for (let i = 0; i < 5; i++)
                    text += possible.charAt(Math.floor(Math.random() * possible.length));

                return text;
            }
        }
    }

</script>

<style scoped>

    .img-bor {
        width: 150px;
    }

    .profile_user {
        text-align: center;
    }
</style>
