<template>
   <div class="row" >
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">AUD-02 {{supplier_id}}</div>
            <div class="card-subtitle mt-2">
              Notas fiscais com valor total {{ selectedPercentage }}% acima da média anterior
            </div>
          </div>
          <div class="card-body chart-body">
            <line-chart v-if="canRenderGraph" :chartdata="chart.chartdata" :options="chart.options"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../../Charts/LineChart'
export default {
  data() {
    return {
      selectedPercentage: '',
      canRenderGraph: false,
      chart: {
        chartdata: {
          labels: [1],
          datasets: [
            {
              label: "Valor da nota",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#478f48",
              data: [1],
              pointBackgroundColor: []
            },
            {
              label: "Média",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#4073d7",
              data: [1],
            },
          ],
        },  
        options: {
          legend: {
            onClick: null
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              label(vars){
                let float = parseFloat(vars.value).toFixed(2)
                let parts = float.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                let formatted = parts.join(".").replace('.', ',')

                if(vars.datasetIndex == 0){  
                  return 'Valor total da nota fiscal: ' + formatted
                  } else { 
                  return 'Média deste momento: ' + formatted
                }
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  methods: {
    getGraph(fd){
      const self = this;
			const api = this.$store.state.api + 'aud2s/graph/';
      
      self.canRenderGraph = false

			self.$http.post(api, fd)
				.then((response) => {

          self.selectedPercentage = response.data.selected_percentage
					self.chart.chartdata.labels = response.data.labels
					self.chart.chartdata.datasets[1].data = response.data.averages
          self.chart.chartdata.datasets[0].data = response.data.totals
          self.chart.chartdata.datasets[0].pointBackgroundColor = response.data.flag_indexes;

          this.canRenderGraph = true
        })
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
  },
  components: {
    LineChart,
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
