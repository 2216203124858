import Products from '../components/Products/Products'

let routes = {
    path: '/products',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Produtos',
            component: Products
        },
    ]
}

export default routes
