import Batch from '../components/Batch/Batch'

let routes = {
    path: '/batch',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Lotes',
            component: Batch
        },
    ]
}

export default routes
