<template>
    <div>
        <div class="ibox animated fadeInRightBig" v-if="!$route.params.operation">
            <div class="ibox-title" style="padding:16px;">
                <h3>
                    {{crudName}}

                    <router-link :to="model_endpoint ? model_endpoint : endPoint + 'add'" class="btn btn-normal purple" v-show="enableAdd" style="float:right;margin-top:-5px;">{{newText}}</router-link>
                </h3>
            </div>
            <div class="ibox-content">
                <div class="block m-b-md text-right" v-if="enableAddModal">
                    <button type="button" class="btn btn-success" @click="addModal">Adicionar {{ crudName }}</button>
                </div>
                <v-server-table ref="table" :tableData="tableData" :name="table" :url="url" :columns="columns" :options="options">
                    <div slot="actions" slot-scope="props">
                        <a v-show="enableDownload" data-toggle="tooltip" title="Download" class="btn btn-info    fa fa-download"
                           @click="download(props.row.file)"></a>
                        <a v-show="enableView" data-toggle="tooltip" title="Consultar" class="btn btn-info    fa fa-eye"
                           @click="view(props.row.id)"></a>
                        <a v-show="enableEdit" data-toggle="tooltip" title="Editar" class="btn btn-warning fa fa-pencil"
                           @click="edit(props.row.id)"></a>
                        <a v-show="enableEditModal" @click="editModal(props.row.id)" data-toggle="tooltip" title="Editar" class="btn btn-warning fa fa-pencil"
                         ></a>
                        <a v-show="enableDelete" data-toggle="tooltip" title="Deletar"
                           class="btn btn-danger  fa fa-trash" @click="destroy(props, props.row, props.index)"></a>

                        <template v-for="extra in extra">
                          <a data-toggle="tooltip" :title="extra.title" :key="extra.id"
                           :class="extra.class" @click="callExtra(extra.name, props, props.row, props.index)">
                           {{extra.label}}
                          </a>
                        </template>
                    </div>
                    <div slot="photo" slot-scope="props">
                        <img :src="props.row.photo" alt="" width="85px">
                    </div>
                    <div slot="cover_photo" slot-scope="props">
                        <img :src="props.row.cover_photo" alt="" class="photo">
                    </div>
                    <div slot="color" slot-scope="props">
                        <div class="box" v-bind:style="{ 'background-color': props.row.color }"></div>
                        <span class="bold">
                            {{ props.row.color }}
                        </span>
                    </div>
                     <div slot="qrcode" slot-scope="props">
                        <img :src="props.row.qrcode" alt="" class="qrcode">
                        <br>
                        <span class="bold">
                            {{ props.row.qrcode_name }}
                        </span>
                    </div>
                </v-server-table>
            </div>
        </div>

        <BaseForm :dataForm="dataForm" />
    </div>
</template>

<script>
    import {Pagination} from 'vue-pagination-2'
    import BaseForm from './BaseForm'

    export default {
        data: function () {
            return {
                records: [],
                pages: [],
                tableData: [],
            }
        },
        props: {
            crudName: String,
            newText: String,
            table: String,
            columns: Array,
            options: Object,
            endPoint: String,
            model_endpoint: String,
            with: String,
            enableAdd: Boolean,
            enableAddModal:Boolean,
            enableEditModal: Boolean,
            enableEdit: Boolean,
            enableDelete: Boolean,
            enableView: Boolean,
            enableDownload: Boolean,
            extra: Array,
            dataForm: Object
        },
        mounted() {
            this.enableAddModal  = this.enableAddModal || false;
            this.enableEditModal = this.enableEditModal || false;
        },
        computed: {
            url() {
                return this.$store.getters.api + this.endPoint + (this.with ? this.with : '')
            }
        },
        methods: {
            callExtra(name, props, row, index) {
              this.$emit(name, {
                props,row,index
              })
            },
            edit(id) {
                this.$router.push({
                    path: this.endPoint + 'edit/' + id
                })
            },
            view(id) {
                this.$router.push({
                    path: this.endPoint + 'view/' + id
                })
            },
            download(file) {
                window.open(file, '_blank');
            },
            destroy(props, row, index) {
                const self = this

                swal({
                    title: "Deseja deletar o registro?",
                    text: "Essa operação não pode ser desfeita",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Sim, deletar!",
                    showLoaderOnConfirm: true,
                    buttons: {
                        catch: {
                            text: "Não",
                            value: "cancel",
                            className: "btn-danger"
                        },
                        confirm: {
                            text: "Sim",
                            value: 'confirm'
                        }
                    },
                })
                    .then((value) => {
                        switch (value) {
                            case 'cancel':
                                swal('Cancelado', 'O registro não foi excluido!', 'info');
                                break;

                            case 'confirm':

                                self.$http.delete(this.url + row.id, {
                                    'id': row.id
                                })
                                    .then((response) => {
                                        self.$refs.table.tableData.splice(index - 1, 1);
                                        swal('Sucesso', 'Registro excluído com sucesso!', 'success');
                                    });
                                break;
                        }
                   })
            },
            addModal: function(){
                this.$emit('addModal');
            },
            editModal: function(id){

                this.$emit('editModal', id);
            }
        },
        components: {
            Pagination,
            BaseForm
        }
    }
</script>

<style scoped>

    .photo {
        max-height: 180px;
    }

</style>
