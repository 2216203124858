import Aud from '../components/Auds/AudComponent'

let routes = {
    path: '/aud/:number/:id',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Aud',
            component: Aud
        },
    ]
}

export default routes
