<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title">AUD-08</div>
          <div class="card-subtitle mt-2">
            Notas fiscais com valor total arredondado e acima de R$ {{ selectedMinimumValue }} 
          </div>
        </div>
        <div class="card-body chart-body">
          <bar-chart ref="my_chart" v-if="canRenderGraph" :chartdata="chart.chartdata" :options="chart.options"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '../../Charts/BarChart'
export default {
  data() {
    return {
      selectedMinimumValue: '',
      canRenderGraph: false,
      chart: {
        chartdata: {
          labels: [],
          datasets: [
            {
              label: "Valor da nota",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              backgroundColor: "#4073d7",
              data: [],
              pointBackgroundColor: []
            },
          ],
        },  
        options: {
          legend: {
            onClick: null
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              label(vars){
                let float = parseFloat(vars.value).toFixed(2)
                let parts = float.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                let formatted = parts.join(".").replace('.', ',')

                if(vars.datasetIndex == 0){  
                  return 'Valor total da nota fiscal: ' + formatted
                  } else { 
                  return 'Média deste momento: ' + formatted
                }
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  methods: {
    getGraph(fd){
      const self = this;
			const api = this.$store.state.api + 'aud8s/graph/'

			self.$http.post(api, fd)
				.then((response) => {
          console.log(response.data);
          self.selectedMinimumValue = response.data.selected_minimum_value
					self.chart.chartdata.labels = response.data.labels
					self.chart.chartdata.datasets[0].data = response.data.totals
          self.canRenderGraph = true
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    }
  },
  components: {
    BarChart,
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
