<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title">AUD-04</div>
          <div class="card-subtitle mt-2">
            Pagamentos ocasionais e relevantes - valores
          </div>
        </div>
        <div class="card-body chart-body">
          <bar-chart ref="my_chart1" v-if="canRenderGraph" :chartdata="chart1.chartdata" :options="chart1.options"/>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="card-title">AUD-04</div>
          <div class="card-subtitle mt-2">
            Pagamentos ocasionais e relevantes - faixas
          </div>
        </div>
        <div class="card-body chart-body">
          <bar-chart ref="my_chart2" v-if="canRenderGraphSecond" :chartdata="chart2.chartdata" :options="chart2.options"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '../../Charts/BarChart'
export default {
  data() {
    return {
      selectedMinimumValue:'',
      canRenderGraph: false,
      canRenderGraphSecond: false,
      chart1: {
        chartdata: {
          labels: [],
          datasets: [
            {
              label: "Valor da nota",
              data: [],
            }
          ],
        },  
        options: {
          legend: {
            display: false,
            onClick: null
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              label(vars){
                let float = parseFloat(vars.value).toFixed(2)
                let parts = float.toString().split(".")
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                let formatted = parts.join(".").replace('.', ',')
                    return 'Valor total da nota fiscal: ' + formatted
                }
              }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      chart2: {
        chartdata: {
          labels: [],
          datasets: [
            {
            }
          ],
        },  
        options: {
          legend: {
            onClick: null
          },
          tooltips: {
            mode: 'index',
            callbacks: {
              label(vars){
                    return 'Quantidade de pagamentos: ' + vars.value
                }
              }
          },
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  methods: {
    getGraph(fd){
      this.getGraph1(fd)
      this.getGraph2(fd)
    },
    getGraph1(fd){
      const self = this;
			const api = this.$store.state.api + 'aud4s/graph1/'

      self.canRenderGraph = false

			self.$http.post(api, fd)
				.then((response) => {
          self.selectedMinimumValue = response.data.selected_minimum_value
					self.chart1.chartdata.labels = response.data.labels
          self.chart1.chartdata.datasets[0].data = response.data.totals
          self.chart1.chartdata.datasets[0]['backgroundColor'] = response.data.colors 
          self.canRenderGraph = true
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    getGraph2(fd){
      const self = this;
			const api = this.$store.state.api + 'aud4s/graph2/'

      self.canRenderGraphSecond = false

			self.$http.post(api, fd)
				.then((response) => {
					self.chart2.chartdata.labels = response.data.labels
          self.chart2.chartdata.datasets = response.data.datasets
          self.canRenderGraphSecond = true
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
  },
  components: {
    BarChart,
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
