<template>
    <div>
        <div class='row'>
            <div class='mx-auto' style='font-weight: bold; font-size: 20px'>
            {{ test.description }} - Realizado no dia {{ formatTime(test.created_at) }}
            </div>
        </div>
        <hr>
    <div class='row'>
        <div class='col-3 aud-container' v-for="aud in auds" :key="aud.number">
            <div :class="['aud-content', {'disabled': aud.amount == null}]">
                <div class='name'>AUD-{{ aud.number }}</div>
                <div class='summary' style='text-align:center'>{{  aud.summary  }}</div>
                <hr>
                <div class='parameters'>
                    <div v-if="aud.parameters">
                        <div class='parameters-title'>Parâmetros: </div>
                        <ul>
                            <li v-for="parameter in aud.parameters" :key="parameter.value">{{  parameter.name  }}: {{  parameter.value  }}</li>
                        </ul>
                    </div>
                    <div v-if="!aud.parameters">
                        <div class='parameters-title'>Parâmetros: </div>
                        Esta análise não possui parâmetros
                    </div>
                </div>
                <hr>
                <div class='amount'>Foram encontradas {{  aud.amount  }} inconsistências</div>
                <hr>
                <div class='btn-redirect'>
                    <button v-if="aud.amount" @click="goToAud(aud.number)" class='btn btn-normal purple'>Ir para a análise</button>
                </div>
            </div>
        </div>

    </div>
        <div class='row text-center'>
            <div class='col-12 text-center'>
                <div class='mx-auto clickable' style='font-weight: bold; font-size: 20px' @click="showInactives = !showInactives">
                    <i :class="['fa', {'fa-chevron-right': !showInactives}, {'fa-chevron-down': showInactives}]"></i> Auds Inativas (clique para ampliar)
                </div>
            </div>
        </div>
        <hr>

        <div class='row' v-show="showInactives">
            <div class='col-3 aud-container' v-for="aud in inactive_auds" :key="aud.number">
                <div :class="['aud-content disabled']">
                    <div class='name'>AUD-{{ aud.number }}</div>
                    <div class='summary' style='text-align:center'>{{  aud.summary  }}</div>
                    <hr>
                    <div class='parameters'>
                        <div>
                            <div class='parameters-title'>Parâmetros: </div>
                            Esta análise não possui parâmetros
                        </div>
                    </div>
                    <hr>
                    <div class='amount'>Aud inativa</div>
                    <hr>
                    <div class='btn-redirect'>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return {
            test: '',
            auds: '',
            showInactives: false,
            inactive_auds: [
                {
                    number: 3,
                    summary: 'Comparação entre fornecedores'
                },
                {
                    number: 7,
                    summary: 'Registros de Compras e Pagamentos aos fins de semana e feriados'
                },
                {
                    number: 9,
                    summary: 'Análise de cadastro de fornecedores - CNPJs'
                },
                {
                    number: 10,
                    summary: 'Análise de cadastro de fornecedores - CPFs'
                },
                {
                    number: 13,
                    summary: 'Pagamentos para contas bancárias no exterior'
                },
                {
                    number: 14,
                    summary: 'Fornecedores atrelados historicamente a irregularidades (por palavras-chave)'
                },
                {
                    number: 15,
                    summary: 'Compras realizadas sem cotações e sem justificativa direta'
                },
            ]
        }
    },
    methods: {
        formatTime(date){
			return moment(date).format('LLL')
        },
        goToAud(number){
            this.$router.push('/aud/' + number + '/' + this.$route.params.id)
        },
        getTestwithAuds(){
			const api = this.$store.state.api + 'tests/audsInfo/' + this.$route.params.id
			const self = this;

			self.$http.get(api)
				.then((response) => {
					self.test = response.data[0]
                    self.auds = response.data[1]
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
			});
        }
    },
    mounted(){
        this.getTestwithAuds();
    }
};
</script>

<style>
    .aud-container {
        margin-bottom: 20px;   
        display: flex;
        position: relative;
    }
    .aud-content {
        width: 100%;
        border: 2px solid #6908b9;
        border-radius: 20px;
        padding: 10px;
        box-shadow: 3px 3px #6908b9, -1em 0 0.4em #c78df7;
        position: relative;
    }
    .aud-content .name {
        color: #6908b9;
        font-weight: 700;
        font-size: 25px;
        text-align: center;
    }
    .aud-content .amount {
        color: #6908b9;
        font-weight: 700;
    }
    .aud-content .parameters-title {
        font-weight: 700;
        color: #6908b9;
    }
    .aud-content .btn-redirect {
        height: 25px;
    }
    .aud-content .btn-redirect button{
        bottom: 8px;
        position: absolute;
        left: 30%;
        width: 40%;
    }
    .aud-content.disabled {
        border: 2px solid gray;
        box-shadow: 3px 3px gray, -1em 0 0.4em gray;
        position: relative;
    }
    .aud-content.disabled .name {
        color: gray;
    }
    .aud-content.disabled .amount {
        color: gray;
    }
    .aud-content.disabled .parameters-title {
        color: gray;
    }
</style>
