<template>
  <div>
    <details-component v-if="aud_number" :aud_number="aud_number" ref="details_component"></details-component>

    <form action="">
      <div class="form-row mb-3">
        <div class="col-1 text-center">
          <button class='btn btn-normal purple' @click="goBackToDesign()"><i class="fas fa-chevron-left"></i> Voltar </button>
        </div>
        <div class="col">
          <select class="form-control" v-model="supplier_id" @change="changeGraph()">
            <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id"> {{ supplier.fantasy_name }} </option>   
          </select>
        </div>
        <div class="col">
          <input v-model="filters.date_ini" type="text" class="form-control" v-mask="'##/##/####'" @keyup="changed_date_ini()"/>
        </div>
        <div class="col">
          <input v-model="filters.date_end" type="text" class="form-control" v-mask="'##/##/####'" @keyup="changed_date_end()"/>
        </div>
        <div class="col">
          <money v-model="filters.total_ini" class="form-control" v-bind="money" v-debounce:800ms="changeGraph"></money>
        </div>
        <div class="col">
          <money v-model="filters.total_end" class="form-control" v-bind="money" v-debounce:800ms="changeGraph"></money>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info">Quantidade de Fornecedores</div>
            <div class="card-small-info small">
              Que possuem notas fiscais repetidas
            </div>
            <div class="card-value-info">{{ suppliers.length }}</div>
            <i class="fas fa-users card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info">Valores Acumulados Pagos</div>
            <div class="card-small-info small">
              Que possuem notas fiscais repetidas
            </div>
            <div class="card-value-info" v-if="kpis.value">{{ kpis.value }} </div>
            <i class="fas fa-money-bill card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info">Quantidade de Transações</div>
            <div class="card-small-info small">
              Que possuem notas fiscais repetidas
            </div>
            <div class="card-value-info">{{ kpis.count }}</div>
            <i class="fas fa-exchange-alt card-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <component v-if="aud_number && supplier_id" ref="graph" :is="'Aud' + aud_number">
    </component>
    <!-- Tabelas -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              Alertas Filtrados<span class="pastel-green">{{ table_lines.length }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">
                      <div class="form-check">
                        <input
                          class="form-check-input position-relative"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                        />
                      </div>
                    </th>
                    <th scope="col">Ações</th>
                    <th scope="col">ID</th>
                    <th scope="col">NF</th>
                    <th scope="col">Nº do pedido</th>
                    <th scope="col">Fornecedor</th>
                    <th scope="col">CNPJ</th>
                    <th scope="col">Data Emissão</th>
                    <th scope="col">Valor Transação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="aud in table_lines" :key="aud.id">
                    <td :style="'background-color: ' + aud.status.color"></td>
                    <td>
                      <div class="form-check">
                        <select class='form-control' v-model="aud.status_id" @change="changed_aud_status(aud)">
                          <option v-for="status in aud_status" :value="status.id" :key="status.id">{{ status.description }}</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <button
                        class="btn btn-crud green"
                        data-tt="tooltip"
                        title="Detalhes"
                        data-toggle="modal"
                        data-target="#modalDetalhes"
                        @click="detailsModal(aud)"
                      >
                        <i class="fas fa-info"></i>
                      </button>
                    </td>
                    <td>{{ aud.id }}</td>
                    <td>{{ aud.invoice.invoice_number }}</td>
                    <td>{{ aud.invoice.order_id }}</td>
                    <td>{{ aud.invoice.supplier.fantasy_name }}</td>
                    <td>{{ aud.invoice.supplier.cnpj_cpf }}</td>
                    <td>{{ format_date(aud.invoice.emission_date) }}</td>
                    <td>{{ aud.invoice.total_formatted }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-more">
              <div class="row">
                <div class="col-md-9 offset-md-3">
                  <div class="table-sub">
                    Legendas:
                    <i class="fas fa-square c-red"></i> Identificado
                    <i class="fas fa-square c-green"></i> Falso Positivo
                    <i class="fas fa-square c-orange"></i> Em Análise
                    <i class="fas fa-square c-purple"></i> Fraude Detectada
                    <i class="fas fa-square c-blue"></i> Concluído
                    <i class="fas fa-square c-black"></i> Inconcluso
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsComponent from './Reusable/Details'
import Aud1 from './Graphs/Aud1'
import Aud2 from './Graphs/Aud2'
import Aud4 from './Graphs/Aud4'
import Aud5 from './Graphs/Aud5'
import Aud6 from './Graphs/Aud6'
import Aud8 from './Graphs/Aud8'
import Aud11 from './Graphs/Aud11'
import Aud12 from './Graphs/Aud12'
import Vue from 'vue'
import {Money} from 'v-money'
export default {
  data() {
    return {
      test_id: null,
      aud_number: null,
      details_modal_tab: 1,
      aud_status: [],
      aud_in_details_modal: null,
      aud_in_document_request_modal: null,
      document_request: {
        name: '',
        email: '',
        note: ''
      },
      new_note: '',
      notes: [],
      audInNotesModal: null,
      kpis: [],
      table_lines: [],
      suppliers: [],
      supplier_id: null,
      money: {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      filters: {
        total_ini: 0,
        total_end: 0,
        date_ini: null,
        date_end: null
      }
    };
  },
  methods: {
    goBackToDesign(){
      this.$router.push('/design/' + this.$route.params.id)
    },
    detailsModal(aud){
      this.$refs.details_component.putAudInDetailsModal(aud)
    },
    date_is_complete_or_empty(string){
      var matches = string.match(/(\d+)/g);
      if(!matches){
        return true
      } else {
        var length = matches.join('').length
        return length == 8
      }
    },
    changed_date_ini(){
      if(this.date_is_complete_or_empty(this.filters.date_ini)){
        this.changeGraph()
      }
    },
    changed_date_end(){
      if(this.date_is_complete_or_empty(this.filters.date_end)){
        this.changeGraph()
      }
    },
    changeGraph(){
      const fd = this.makeFormData()
      this.getTable(fd)
      this.getKpis(fd)
      this.$refs.graph.getGraph(this.makeFormData())
    },
    format_date(date){
      return moment(date).format('DD/MM/YYYY')
    },
    makeFormData(){
      const fd = new FormData()

      fd.append('test_id', this.test_id)
      fd.append('supplier_id', this.supplier_id)
      fd.append('date_ini', this.filters.date_ini ? this.date_is_complete_or_empty(this.filters.date_ini) ? this.filters.date_ini : '' : '')
      fd.append('date_end', this.filters.date_end ? this.date_is_complete_or_empty(this.filters.date_end) ? this.filters.date_end : '' : '')
      fd.append('total_ini', this.filters.total_ini ? this.filters.total_ini : '')
      fd.append('total_end', this.filters.total_end ? this.filters.total_end : '')

      return fd
    },
    getTable(fd){
      const self = this;
			const api = this.$store.state.api + 'aud' + self.aud_number + 's/table/';

			self.$http.post(api, fd)
				.then((response) => {
					self.table_lines = response.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    getSuppliers(){
      const self = this;
			const api = this.$store.state.api + 'aud' + self.aud_number + 's/suppliers/' + self.$route.params.id;

			self.$http.get(api)
				.then((response) => {
          self.suppliers = response.data
          if(self.suppliers.length){
            self.supplier_id = self.suppliers[0].id
            Vue.nextTick(function(){
              self.changeGraph()
            })
          }
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    getKpis(fd){
      const self = this;
			const api = this.$store.state.api + 'aud' + self.aud_number + 's/kpis/';

			self.$http.post(api, fd)
				.then((response) => {
          self.kpis = response.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    getAudStatus(){
      const self = this;
			const api = this.$store.state.api + 'aud-status?paginated=false';

			self.$http.get(api)
				.then((response) => {
          self.aud_status = response.data.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    changed_aud_status(aud){
      const self = this;
			const api = this.$store.state.api + 'aud' + self.aud_number + 's/changed-status';

      const fd = new FormData()
      fd.append('aud_id', aud.id)
      fd.append('status_id', aud.status_id)

			self.$http.post(api, fd)
				.then((response) => {
          self.$message('Sucesso', 'Status alterado com sucesso', 'success');
          aud.status = response.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    }
  },
  mounted(){
    this.test_id = this.$route.params.id
    this.aud_number = this.$route.params.number
    this.getSuppliers()
    this.getAudStatus()
  },
  components: {
    DetailsComponent,
    Aud1,
    Aud2,
    Aud4,
    Aud5,
    Aud6,
    Aud8,
    Aud11,
    Aud12,
    Money
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
