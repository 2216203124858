import Crud from '../components/Test/Crud'
import Model from '../components/Test/Model'

let routes = {
    path: '/tests',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/tests',
            name: 'Testes',
            component: Crud
        },
        {
            path: '/tests/model/:id?',
            name: 'Teste',
            component: Model
        },
    ]
};


export default routes
