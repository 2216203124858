import Design from '../components/Design'

let routes = {
    path: '/design/:id',
    component: resolve => require(['../components/layout/App'], resolve),
    children: [
        {
            path: '/',
            name: 'Design',
            component: Design
        },
    ]
};


export default routes
