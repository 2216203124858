<template>
    <div id='presentation' class='container-fluid'>
        <div class='title'>
            <i class='fa fa-chevron-right'></i>
            Módulos disponíveis
        </div>
        <div class='row'>
            <div class='col-4 offset-4 card effects'>
                <h1 class='title'>Auditee Industry</h1>
                <router-link :to="'/'" class='card-btn'>
                    Acessar
                    <i class='fa fa-sign-in-alt'></i>
                </router-link>
                <b>Auditee Industry</b>
            </div>
        </div>
         <div class='title'>
            <i class='fa fa-chevron-right'></i>
            Outros módulos
        </div>
        <div class='row disabled-modules'>
            <div class='col-4'>
                <div class='card effects'>
                    <h1 class='title'>Auditee Financial</h1>
                    <b>Auditee Financial Services</b>
                </div>                
            </div>
            <div class='col-4'>
                <div class='card effects'>
                    <h1 class='title'>Auditee Human</h1>
                    <b>Auditee Human Resources</b>
                </div>  
            </div>
            <div class='col-4'>
                <div class='card effects'>
                    <h1 class='title'>Auditee Sales</h1>
                    <b>Auditee Sales</b>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {}
  }
};
</script>

<style scoped>
    #presentation .card-btn {
        border-top: 1px solid gray; 
        border-right: none;
        border-left: none;
        border-bottom: 1px solid gray; 
    }
    #presentation .card-btn:hover {
        border: none;
    }
    #presentation .card-btn:after {
        border-top: 1px solid #6908b9; 
        border-right: none;
        border-left: none;
        border-bottom: 1px solid #6908b9; 
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        display:block;
        content: '';
    }
    #presentation .card-btn:before {
        border-top: 1px solid #6908b9; 
        border-right: none;
        border-left: none;
        border-bottom: 1px solid #6908b9; 
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        display:block;
        content: '';
    }
    #presentation .card-btn:hover:before {
        transform: scaleX(1); 
    }
    #presentation .card-btn:hover:after {
        transform: scaleX(1); 
    }
    #presentation .effects {
        position: relative;
        top: 0;
        transition: top ease 0.5s;
    }
    #presentation .effects:hover {
        top: -10px;
    }
    #presentation {
        margin-top: 50px;
    }
    #presentation .card {
        box-shadow: 0px 2px 4px #666;
    }
    #presentation .title {
        color: black;
        font-weight: 700;
    }
    #presentation .title i {
        color: #6908b9;
    }

    #presentation .card .title {
        color: #6908b9;
        font-size: 40px;
        margin: 60px 0px 90px 0px;
        border: none;
        text-align: center;
    }

    #presentation .card .card-btn {
        color: black;
        font-size: 20px;
        margin-bottom: 20px;
        background-color: white;
        width: 100%;
        text-decoration: none;
        text-align: center;
    }
    #presentation .card .card-btn::after {
        font-weight: 700;
        padding-left: 5px;
        transition: all 0.3s;
        background-color: transparent;
    }

    #presentation .card b {
        color: black;
        text-align: center;
        margin-bottom: 10px;
    }


    .disabled-modules .card-btn, .disabled-modules .title, .disabled-modules b {
        color: gray !important;
    }
    .disabled-modules .card-btn {
        border-color: gray !important;
    }
</style>
