<template>
  <div>
    <!-- Filtros -->
    <form action="">
      <div class="form-row mb-3">
        <div class="col">
          <select class="form-control">
            <option>Fornecedor</option>
          </select>
        </div>
        <div class="col">
          <input type="date" class="form-control" />
        </div>
        <div class="col">
          <input type="date" class="form-control" />
        </div>
      </div>
    </form>
    <!-- Indicadores -->
    <div class="row">
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info">Valor Pago</div>
            <div class="card-value-info">200.000,00</div>
            <div class="card-percent down">13,5%</div>
            <i class="fas fa-money-bill card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info">Quantidade de Fornecedores</div>
            <div class="card-value-info">58</div>
            <div class="card-percent up">8,2%</div>
            <i class="fas fa-users card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info">Quantidade de Transações</div>
            <div class="card-value-info">671</div>
            <div class="card-percent down">11,7%</div>
            <i class="fas fa-exchange-alt card-icon"></i>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="card">
          <div class="card-body position-relative overflow-hidden">
            <div class="card-small-info">Dif. Acumumulada de Valores</div>
            <div class="card-value-info">73%</div>
            <div class="card-percent up">3,6%</div>
            <i class="fas fa-money-bill-wave card-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Barras -->
      <div class="col-md-6">
        <div class="card">
          <div class="accordion" id="accordionBars">
            <div :class="['collapse', { show: chosenTab == 1 }]">
              <div class="card-header">
                <div class="card-title">Volume em (R$) Transacionado</div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <div class="card-small-title">Fornecedor</div>
                  </div>
                  <div class="col-3">
                    <div class="card-small-title">R$</div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">InnovareTI</div>
                  <div class="col-3 text-right pr-5">200.000,00</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="200000"
                        aria-valuemin="0"
                        aria-valuemax="200000"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">HumanFlow</div>
                  <div class="col-3 text-right pr-5">150.000,00</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="150000"
                        aria-valuemin="0"
                        aria-valuemax="200000"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">Quallifica</div>
                  <div class="col-3 text-right pr-5">150.000,00</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="150000"
                        aria-valuemin="0"
                        aria-valuemax="200000"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">iFS - FlowServices</div>
                  <div class="col-3 text-right pr-5">100.000,00</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 50%"
                        aria-valuenow="100000"
                        aria-valuemin="0"
                        aria-valuemax="200000"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">IPP</div>
                  <div class="col-3 text-right pr-5">100.000,00</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 50%"
                        aria-valuenow="100000"
                        aria-valuemin="0"
                        aria-valuemax="200000"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">SSO</div>
                  <div class="col-3 text-right pr-5">50.000,00</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 25%"
                        aria-valuenow="50000"
                        aria-valuemin="0"
                        aria-valuemax="200000"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="['collapse', { show: chosenTab == 2 }]">
              <div class="card-header">
                <div class="card-title">Transações(Qtd.) Realizadas</div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <div class="card-small-title">Fornecedor</div>
                  </div>
                  <div class="col-3">
                    <div class="card-small-title">Qtd.</div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">InnovareTI</div>
                  <div class="col-3">400</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="400"
                        aria-valuemin="0"
                        aria-valuemax="400"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">HumanFlow</div>
                  <div class="col-3">300</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="300"
                        aria-valuemin="0"
                        aria-valuemax="400"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">Quallifica</div>
                  <div class="col-3">300</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 75%"
                        aria-valuenow="300"
                        aria-valuemin="0"
                        aria-valuemax="400"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">iFS - FlowServices</div>
                  <div class="col-3">200</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 50%"
                        aria-valuenow="200"
                        aria-valuemin="0"
                        aria-valuemax="400"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">IPP</div>
                  <div class="col-3">200</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 50%"
                        aria-valuenow="200"
                        aria-valuemin="0"
                        aria-valuemax="400"
                      ></div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-3">SSO</div>
                  <div class="col-3">100</div>
                  <div class="col-6">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 25%"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="400"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-6">
                  <a
                    :class="[
                      'bar-link clickable',
                      { collapsed: chosenTab == 2 },
                    ]"
                    @click="chosenTab = 1"
                    >Volume</a
                  >
                </div>
                <div class="col-6">
                  <a
                    :class="[
                      'bar-link clickable',
                      { collapsed: chosenTab == 1 },
                    ]"
                    @click="chosenTab = 2"
                    >Transações</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Gráficos -->
      <div class="col-md-6">
        <div class="card">
          <carousel
            class="carousel slide"
            :per-page="1"
            :navigationEnabled="true"
            :paginationEnabled="false"
          >
            <slide>
              <div class="carousel-item active">
                <div class="card-header">
                  <div class="card-title">AUD-01</div>
                  <div class="card-subtitle mt-2">
                    <a href="test01.html"
                      >Comparação de compras de um único fornecedor para
                      entender os padrões de pagamento</a
                    >
                  </div>
                </div>
                <div class="card-body chart-body">
                  <line-chart :chartdata="aud01Chart.chartdata" :options="aud01Chart.options"/>
                </div>
              </div>
            </slide>
            <slide>
              <div class="carousel-item active">
                <div class="card-header">
                  <div class="card-title">AUD-02</div>
                  <div class="card-subtitle mt-2">
                    <a href="#">Pagamentos repetidos para o mesmo fornecedor</a>
                  </div>
                </div>
                <div class="card-body chart-body">
                  <bar-chart />
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </div>
    <!-- Tabelas -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              Últimos Alertas<span class="pastel-green">10</span>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Ações</th>
                    <th scope="col">ID</th>
                    <th scope="col">Fornecedor</th>
                    <th scope="col">CNPJ</th>
                    <th scope="col">NF</th>
                    <th scope="col">Valor Transação</th>
                    <th scope="col">Data Pag.</th>
                    <th scope="col">Aprovador Compra</th>
                    <th scope="col">Financeiro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <button
                        class="btn btn-crud purple"
                        data-tt="tooltip"
                        title="Anotações"
                        data-toggle="modal"
                        data-target="#modalAnotacoes"
                      >
                        <i class="fas fa-clipboard-list"></i>
                      </button>
                      <button
                        class="btn btn-crud blue"
                        data-tt="tooltip"
                        title="Solicitar Documentos"
                        data-toggle="modal"
                        data-target="#modalDocumentos"
                      >
                        <i class="fas fa-file-invoice"></i>
                      </button>
                      <button
                        class="btn btn-crud green"
                        data-tt="tooltip"
                        title="Detalhes"
                        data-toggle="modal"
                        data-target="#modalDetalhes"
                      >
                        <i class="fas fa-info"></i>
                      </button>
                    </td>
                    <td>1050</td>
                    <td>Innovare TI</td>
                    <td>87.535.626/0001-80</td>
                    <td>12864</td>
                    <td class="text-right">50.000,00</td>
                    <td>21/03/2021</td>
                    <td>Anderson Gomes</td>
                    <td>Mirela Dias</td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-crud purple"
                        data-tt="tooltip"
                        title="Anotações"
                        data-toggle="modal"
                        data-target="#modalAnotacoes"
                      >
                        <i class="fas fa-clipboard-list"></i>
                      </button>
                      <button
                        class="btn btn-crud blue"
                        data-tt="tooltip"
                        title="Solicitar Documentos"
                        data-toggle="modal"
                        data-target="#modalDocumentos"
                      >
                        <i class="fas fa-file-invoice"></i>
                      </button>
                      <button
                        class="btn btn-crud green"
                        data-tt="tooltip"
                        title="Detalhes"
                        data-toggle="modal"
                        data-target="#modalDetalhes"
                      >
                        <i class="fas fa-info"></i>
                      </button>
                    </td>
                    <td>1050</td>
                    <td>Innovare TI</td>
                    <td>87.535.626/0001-80</td>
                    <td>12864</td>
                    <td class="text-right">50.000,00</td>
                    <td>21/03/2021</td>
                    <td>Anderson Gomes</td>
                    <td>Mirela Dias</td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-crud purple"
                        data-tt="tooltip"
                        title="Anotações"
                        data-toggle="modal"
                        data-target="#modalAnotacoes"
                      >
                        <i class="fas fa-clipboard-list"></i>
                      </button>
                      <button
                        class="btn btn-crud blue"
                        data-tt="tooltip"
                        title="Solicitar Documentos"
                        data-toggle="modal"
                        data-target="#modalDocumentos"
                      >
                        <i class="fas fa-file-invoice"></i>
                      </button>
                      <button
                        class="btn btn-crud green"
                        data-tt="tooltip"
                        title="Detalhes"
                        data-toggle="modal"
                        data-target="#modalDetalhes"
                      >
                        <i class="fas fa-info"></i>
                      </button>
                    </td>
                    <td>1050</td>
                    <td>Innovare TI</td>
                    <td>87.535.626/0001-80</td>
                    <td>12864</td>
                    <td class="text-right">50.000,00</td>
                    <td>21/03/2021</td>
                    <td>Anderson Gomes</td>
                    <td>Mirela Dias</td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-crud purple"
                        data-tt="tooltip"
                        title="Anotações"
                        data-toggle="modal"
                        data-target="#modalAnotacoes"
                      >
                        <i class="fas fa-clipboard-list"></i>
                      </button>
                      <button
                        class="btn btn-crud blue"
                        data-tt="tooltip"
                        title="Solicitar Documentos"
                        data-toggle="modal"
                        data-target="#modalDocumentos"
                      >
                        <i class="fas fa-file-invoice"></i>
                      </button>
                      <button
                        class="btn btn-crud green"
                        data-tt="tooltip"
                        title="Detalhes"
                        data-toggle="modal"
                        data-target="#modalDetalhes"
                      >
                        <i class="fas fa-info"></i>
                      </button>
                    </td>
                    <td>1050</td>
                    <td>Innovare TI</td>
                    <td>87.535.626/0001-80</td>
                    <td>12864</td>
                    <td class="text-right">50.000,00</td>
                    <td>21/03/2021</td>
                    <td>Anderson Gomes</td>
                    <td>Mirela Dias</td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-crud purple"
                        data-tt="tooltip"
                        title="Anotações"
                        data-toggle="modal"
                        data-target="#modalAnotacoes"
                      >
                        <i class="fas fa-clipboard-list"></i>
                      </button>
                      <button
                        class="btn btn-crud blue"
                        data-tt="tooltip"
                        title="Solicitar Documentos"
                        data-toggle="modal"
                        data-target="#modalDocumentos"
                      >
                        <i class="fas fa-file-invoice"></i>
                      </button>
                      <button
                        class="btn btn-crud green"
                        data-tt="tooltip"
                        title="Detalhes"
                        data-toggle="modal"
                        data-target="#modalDetalhes"
                      >
                        <i class="fas fa-info"></i>
                      </button>
                    </td>
                    <td>1050</td>
                    <td>Innovare TI</td>
                    <td>87.535.626/0001-80</td>
                    <td>12864</td>
                    <td class="text-right">50.000,00</td>
                    <td>21/03/2021</td>
                    <td>Anderson Gomes</td>
                    <td>Mirela Dias</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <a href="" class="show-more">Exibir mais</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import LineChart from "./Charts/LineChart";
export default {
  data() {
    return {
      chosenTab: 1,
      aud01Chart: {
        chartdata: {
          labels: ["Jan", "Fev", "Mar", "Abr", "Mai"],
          datasets: [
            {
              label: "Volume (R$) 01",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#1BF0DF",
              data: [65000, 30000, 20000, 15000, 45000],
            },
            {
              label: "Volume (R$) 02",
              fill: false,
              tension: false,
              pointRadius: 5,
              pointHitRadius: 10,
              pointHoverRadius: 10,
              borderColor: "#87D52F",
              data: [55000, 40000, 40000, 15000, 35000],
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  components: {
    LineChart,
    Carousel,
    Slide,
  },
};
</script>
<style>
</style>
