<template>
  <div>
    <div class="modal fade" id="modalDetalhe" tabindex="-1" role="dialog">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalAnotacoesTitle">Detalhes</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div v-if="aud_in_details_modal" class="modal-body">
            <ul class="nav nav-tabs mb-3">
              <li :class="['nav-item clickable']">
                <a :class="['nav-link', {'active': details_modal_tab == 1 ? 1 : 0}]" @click="details_modal_tab = 1">Dados</a>
              </li>
              <li :class="['nav-item clickable']">
                <a :class="['nav-link', {'active': details_modal_tab == 2 ? 1 : 0}]" @click="details_modal_tab = 2">
                  Logs <span class="badge badge-pill badge-info">{{ aud_in_details_modal.logs.length }}</span>
                </a>
              </li>
              <li :class="['nav-item clickable']">
                <a :class="['nav-link', {'active': details_modal_tab == 3 ? 1 : 0}]" @click="details_modal_tab = 3">Solicitar Documentos</a>
              </li>
              <li :class="['nav-item clickable']">
                <a :class="['nav-link', {'active': details_modal_tab == 4 ? 1 : 0}]" @click="details_modal_tab = 4">Anotações</a>
              </li>
            </ul>
            <div class='details-container'>
              <div v-show="details_modal_tab == 1">
                <div v-if="aud_number == 2" class="alert alert-info" role="alert">
                  Esta nota fiscal não passou no teste em razão de seu 
                  valor de {{ aud_in_details_modal.invoice.total_formatted}}, que ficou
                  {{ aud_in_details_modal.percentage_formatted }}% acima da média contabilizada,
                  ultrapassando o limite estipulado de 75%.
                </div>
                <table class='table table-hover'>
                  <thead>
                    <tr>
                      <th>Dados da nota fiscal</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class='strong'>Fornecedor</td>
                      <td>{{ aud_in_details_modal.invoice.supplier.fantasy_name }}</td>
                    </tr>
                    <tr>
                      <td class='strong'>Filial</td>
                      <td>{{ aud_in_details_modal.invoice.branch }}</td>
                    </tr>
                    <tr>
                      <td class='strong'>Produto</td>
                      <td>{{ aud_in_details_modal.invoice.product.description }}</td>
                    </tr>
                    <tr>
                      <td class='strong'>Quantidade</td>
                      <td>{{ aud_in_details_modal.invoice.amount }}</td>
                    </tr>
                    <tr>
                      <td class='strong'>Valor unitário</td>
                      <td>{{ aud_in_details_modal.invoice.unitary_value_formatted }}</td>
                    </tr>
                    <tr>
                      <td class='strong'>Valor total</td>
                      <td>{{ aud_in_details_modal.invoice.total_formatted }}</td>
                    </tr>
                    <tr>
                      <td class='strong'>Data da emissão da nota fiscal</td>
                      <td>{{ formatDate(aud_in_details_modal.invoice.emission_date) }}</td>
                    </tr>
                  </tbody>
                </table>
                <table class='table table-hover mt-3'>
                  <thead>
                    <tr>
                      <th>Dados da análise</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="aud_number == 2">
                      <td class='strong'>Ordem nas notas analisadas</td>
                      <td>{{ aud_in_details_modal.index }}</td>
                    </tr>
                    <tr>
                      <td class='strong'>Data da análise</td>
                      <td>{{ formatDateTime(aud_in_details_modal.created_at) }}</td>
                    </tr>
                    <tr v-if="aud_number == 2">
                      <td class='strong'>Média antes desta nota fiscal</td>
                      <td>{{ aud_in_details_modal.average_formatted }}</td>
                    </tr>
                    <tr v-if="aud_number == 2">
                      <td class='strong'>Média após esta nota fiscal</td>
                      <td>{{ aud_in_details_modal.new_average_formatted }}</td>
                    </tr>
                    <tr v-if="aud_number == 2">
                      <td class='strong'>Porcentagem acima da média</td>
                      <td><span class="badge badge-danger"><b>{{ aud_in_details_modal.percentage_formatted }}%</b></span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="aud_in_details_modal.logs.length" v-show="details_modal_tab == 2">
                <table class='table table-hover'>
                  <thead>
                    <tr>
                      <th>Data e hora</th>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="log in aud_in_details_modal.logs">
                      <td>{{ formatDateTime(log.created_at) }}</td>
                      <td>{{ log.note }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-show="details_modal_tab == 3">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <input type="text" class="form-control my-2" placeholder="Nome do Documento" v-model="document_request.name">
                      </div>
                      <div class="col-6">
                        <input type="email" class="form-control my-2" placeholder="E-mail do Destinatário" v-model="document_request.email">
                      </div>
                    </div>
                    <textarea class="form-control my-2" placeholder="Coloque aqui sua anotação" rows="3" v-model="document_request.note"></textarea>
                    <button class="btn btn-main" @click="saveDocumentRequest()">Enviar</button>
                  </div>
                </div>
              </div>
              <div v-show="details_modal_tab == 4">
                <div class="row">
                  <div class="col-12">
                    <div class="notes" v-for="note in notes" :key="note.id">
                      <p>{{ note.note }}</p>
                      <div class="infos">{{ note.user.name }} - {{ formatDateTime(note.created_at) }}</div>
                      <button class='btn btn-normal purple' type="button" @click="download(note.file_http)" v-if="note.file">Baixar anexo</button>
                    </div>
                    <textarea class="form-control mt-4 mb-2" placeholder="Coloque aqui sua anotação" rows="3" v-model="new_note"></textarea>
                    <input type="file" id="file" class="form-control my-2">
                    <button class="btn btn-main" @click="saveNote()">Enviar</button>
                  </div>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  props: [
    'aud_number'
  ],
  data() {
    return {
      details_modal_tab: 1,
      aud_status: [],
      aud_in_details_modal: null,
      document_request: {
        name: '',
        email: '',
        note: ''
      },
      new_note: '',
      notes: [],
    };
  },
  methods: {
    formatDate(date) {
			const just_date_time = date.split('T')[0]
			const date_splited = just_date_time.split('-')
			return date_splited[2] + '/' + date_splited[1] + '/' + date_splited[0]
		},
    download(url){
      window.open(url, '_blank')
    },
    formatDateTime(datetime) {
			const date_and_time = datetime.split('T')
			const date_splited = date_and_time[0].split('-')
			const time = date_and_time[1].substring(0, 8)
			return date_splited[2] + '/' + date_splited[1] + '/' + date_splited[0] + ' ' + time
    },
    saveDocumentRequest(){
      const self = this;
			const api = this.$store.state.api + 'document-requests/';

      const fd = new FormData()
      fd.append('note', self.document_request.note)
      fd.append('email', self.document_request.email)
      fd.append('name', self.document_request.name)
      fd.append('aud_id', self.aud_in_details_modal.id)
      fd.append('aud_number', self.aud_number)

			self.$http.post(api, fd)
				.then((response) => {
          self.document_request = {
            name: '',
            email: '',
            note: ''
          }
					self.$message('Sucesso', 'Solicitação registrada com sucesso', 'success');
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    saveNote(){
      const self = this;
			const api = this.$store.state.api + 'notes/';

      const fd = new FormData()
      fd.append('note', self.new_note)
      fd.append('aud_id', self.aud_in_details_modal.id)
      fd.append('aud_number', self.aud_number)

      let file = document.getElementById('file').files[0]
      if(file){
        fd.append('file', file)
      }

			self.$http.post(api, fd)
				.then((response) => {
          self.reloadNotes()
          self.new_note = ''
          document.getElementById('file').value = ''
					self.$message('Sucesso', 'Observação registrada com sucesso', 'success');
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    putAudInDetailsModal(aud){
      const self = this;
			const api = this.$store.state.api + 'aud' + self.aud_number + 's?id=' + aud.id + '&with[]=logs&with[]=invoice&with[]=invoice.supplier&with[]=invoice.product';

			self.$http.get(api)
				.then((response) => {
          self.aud_in_details_modal = response.data.data[0]
          self.reloadNotes()
          $("#modalDetalhe").modal('show')
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    reloadNotes(){
      const self = this;
			const api = this.$store.state.api + 'notes?aud_id=' + self.aud_in_details_modal.id + '&aud_number=' + self.aud_number + '&with[]=user&orderByDesc[]=id';

			self.$http.get(api)
				.then((response) => {
          self.notes = response.data.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    format_date(date){
      return moment(date).format('DD/MM/YYYY')
    },
    getAudStatus(){
      const self = this;
			const api = this.$store.state.api + 'aud-status?paginated=false';

			self.$http.get(api)
				.then((response) => {
          self.aud_status = response.data.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    },
    changed_aud_status(aud){
      const self = this;
			const api = this.$store.state.api + 'aud' + self.aud_number + 's/changed-status';

      const fd = new FormData()
      fd.append('aud_id', aud.id)
      fd.append('status_id', aud.status_id)

			self.$http.post(api, fd)
				.then((response) => {
          self.$message('Sucesso', 'Status alterado com sucesso', 'success');
          aud.status = response.data
				})
				.catch((error) => {
					self.$message('Erro', error.response.data, 'error');
				});
    }
  },
  mounted(){
    this.getAudStatus()
  }
};
</script>

<style>
.strong {
  font-weight: 700;
}
tr:hover {
  background-color: #ead6fa;
}
.details-container {
  height: 720px;
  overflow-y: scroll
}
</style>
